import { dinero, toFormat, toUnit, up } from 'dinero.js'
import { EUR, AUD, USD, GBP } from '@dinero.js/currencies'
import type { Currency } from '@dinero.js/currencies'
import { store } from '@/store/index'

export const currencies: Record<string, Currency<number> & { locale: string }> = {
  EUR: { ...EUR, locale: 'nl-NL' },
  AUD: { ...AUD, locale: 'en-AU' },
  USD: { ...USD, locale: 'en-US' },
  GBP: { ...GBP, locale: 'en-GB' }
}

function getCurrency(): Currency<number> & { locale: string } {
  return store.state.studio.studioDetails ? currencies[store.state.studio.studioDetails.currencyCode] : currencies.GBP
}

export function currencyToInt(amount: number) {
  const currency = getCurrency()
  const factor = currency.base ** currency.exponent
  return amount * factor
}

export function inputFieldFormat(amount: number) {
  const currency = getCurrency()
  const d = dinero({ currency: currency, amount })
  // eslint-disable-next-line
  // @ts-ignore
  return toUnit(d, { round: up })
}

export function intlFormat(amount: number) {
  const currency = getCurrency()
  const d = dinero({ currency: currency, amount })
  // eslint-disable-next-line
  // @ts-ignore
  return toFormat(d, ({ amount }) => {
    return amount.toLocaleString(currency.locale, {
      style: 'currency',
      currency: currency.code,
      maximumFractionDigits: 2
    })
  })
}

export default {
  currencyToInt,
  inputFieldFormat,
  intlFormat
}
