
import { defineComponent, ref } from 'vue'
import DashboardSection from '@/components/atom/DashboardSection.vue'
import CustomButton from '@/components/atom/CustomButton.vue'
import TableLayout from '@/components/templates/TableLayout.vue'
import TableTile from '@/components/templates/TableTile.vue'
import NoResult from '@/components/molecules/NoResult.vue'
import authApi from '@/api/Authenticated'
import { useRoute } from 'vue-router'
import { projectTypes } from '@shared/*'
import useDateFormatter from '@/utils/dateFormatter'
import { intlFormat } from '@/utils/currencyFunctions'

export default defineComponent({
  components: {
    DashboardSection,
    CustomButton,
    TableLayout,
    TableTile,
    NoResult
  },
  setup() {
    const route = useRoute()
    const projectId = +route.params.id
    const { dateFormatter } = useDateFormatter()

    const projectDetails = ref<projectTypes.ProjectInvoiceResponse | { invoices: projectTypes.InvoicesArray }>({
      invoices: []
    })

    const currentSort = ref('')
    const currentSortDir = ref('desc')
    const calculateAmount = function () {
      projectDetails.value.invoices = projectDetails.value.invoices.map((e: any) => {
        const totalAmount = e.invoiceItems
          .map((item: any) => {
            return item.unitsCount * item.costPerUnit
          })
          .reduce((a: any, b: any) => a + b, 0)
        return {
          ...e,
          amount: totalAmount
        }
      })
    }
    const sortAndFetch = async (col: string) => {
      if (currentSort.value === col && col !== 'amount') {
        currentSortDir.value = currentSortDir.value === 'asc' ? 'desc' : 'asc'
      } else if (col === 'amount') {
        if (currentSortDir.value === 'desc') {
          projectDetails.value.invoices = projectDetails.value.invoices.sort((x: any, y: any) => {
            return y.amount - x.amount
          })
        } else {
          projectDetails.value.invoices = projectDetails.value.invoices.sort((x: any, y: any) => {
            return x.amount - y.amount
          })
        }
        currentSortDir.value = currentSortDir.value === 'asc' ? 'desc' : 'asc'
        currentSort.value = col

        return
      } else {
        currentSort.value = col
      }
      projectDetails.value = await authApi.getProjectInvoices(projectId, { [currentSort.value]: currentSortDir.value })
      calculateAmount()
    }

    sortAndFetch('date')

    const getInvoiceStatus = function (dueDate: Date, status: string, invoiceDate: Date) {
      const todayDate = new Date()
      invoiceDate = new Date(invoiceDate)
      dueDate = new Date(dueDate)
      const diffTime = Math.ceil((new Date(dueDate).getTime() - todayDate.getTime()) / (1000 * 60 * 60 * 24))
      if (status === 'Paid') {
        return 'Paid'
      } else if (status === 'Sent') {
        return 'Sent'
      } else if (status === 'Archived') {
        return 'Archived'
      } else if (invoiceDate > todayDate) {
        return 'upcoming'
      } else if (invoiceDate < todayDate && dueDate > todayDate && diffTime > 3) {
        return 'Due'
      } else if (dueDate > todayDate && diffTime <= 3) {
        return 'Payment Due'
      } else if (dueDate < todayDate) {
        return 'Over Due'
      } else {
        return ''
      }
    }

    const getInvoiceStatusClass = function (dueDate: Date, status: string, invoiceDate: Date) {
      const todayDate = new Date()
      invoiceDate = new Date(invoiceDate)
      dueDate = new Date(dueDate)
      const diffTime = Math.ceil((new Date(dueDate).getTime() - todayDate.getTime()) / (1000 * 60 * 60 * 24))
      if (status === 'Paid') {
        return 'status-green'
      } else if (status === 'Sent') {
        return 'status-green'
      } else if (status === 'Archived') {
        return 'status-grey'
      } else if (invoiceDate > todayDate) {
        return 'status-grey'
      } else if (invoiceDate < todayDate && dueDate > todayDate && diffTime > 3) {
        return 'status-yellow'
      } else if (dueDate > todayDate && diffTime <= 3) {
        return 'status-yellow'
      } else if (dueDate < todayDate) {
        return 'status-red'
      } else {
        return ''
      }
    }

    const deleteInvoice = async (invoiceId: number) => {
      const res = await authApi.deleteInvoice(+projectId, +invoiceId)
      if (res) {
        const index = projectDetails.value.invoices.findIndex((e) => e.id === invoiceId)
        if (index !== -1) {
          projectDetails.value.invoices.splice(index, 1)
        }
      }
    }
    const updateInvoiceStatus = async (invoiceId: number, status: any) => {
      const value = {
        status: status
      }

      const res = await authApi.updateInvoiceStatus(+projectId, +invoiceId, value)
      if (res) {
        await sortAndFetch('date')
      }
    }
    const downloadInvoice = async (invoiceId: number) => {
      const res = await authApi.downloadInvoice(+projectId, +invoiceId)
      if (res) {
        window.open(res.link, '_blank')
      }
    }

    const archiveInvoice = async (invoiceId: number) => {
      updateInvoiceStatus(invoiceId, 'Archived')
    }

    return {
      projectId,
      dateFormatter,
      projectDetails,
      getInvoiceStatus,
      sortAndFetch,
      getInvoiceStatusClass,
      deleteInvoice,
      updateInvoiceStatus,
      downloadInvoice,
      intlFormat,
      archiveInvoice
    }
  }
})
