<template>
  <div v-if="projectDetails && Object.keys(projectDetails).length > 0">
    <DashboardSection>
      <template #top-right-button>
        <CustomButton
          iconClass="plus"
          label="New invoice"
          color="grey"
          size="small"
          @click="$router.push({ name: 'EditProject', params: { id: projectId } })"
        />
      </template>
      <template #content>
        <TableLayout>
          <template #left-headings>
            <p class="left-heading-item" @click="sortAndFetch('invoiceNumber')">
              Invoice
              <img src="@/assets/icons/sort.svg" />
            </p>
            <p class="left-heading-item mobile-hide" @click="sortAndFetch('date')">
              Invoice date
              <img src="@/assets/icons/sort.svg" />
            </p>
            <p class="left-heading-item">PO number</p>
            <p class="left-heading-item mobile-hide" @click="sortAndFetch('amount')">
              Amount
              <img src="@/assets/icons/sort.svg" />
            </p>
          </template>
          <template #right-headings>
            <p class="right-heading-item">Status</p>
          </template>

          <TableTile v-for="invoice in projectDetails.invoices" :key="invoice.id">
            <template #tileContent>
              <router-link
                :to="
                  !invoice.invoiceNumber
                    ? { name: 'InvoiceDetail', params: { projectId: +$route.params.id, invoiceId: invoice.id } }
                    : ''
                "
                class="invoice-main"
                :class="getInvoiceStatusClass(invoice.dueDate, invoice.status, invoice.date)"
              >
                <div class="left-content">
                  <div>
                    <div class="left-text highlight">
                      {{ invoice.invoiceNumber ? invoice.invoiceNumber : 'N.A' }}
                    </div>
                    <div class="left-text mobile-view">{{ dateFormatter(invoice.date) }}</div>
                  </div>
                  <div class="mobile-hide">{{ dateFormatter(invoice.date) }}</div>
                  <div>{{ projectDetails.purchaseOrder }}</div>
                  <div class="mobile-hide">{{ intlFormat(+invoice.amount) }}</div>
                </div>
                <div class="right-content">
                  <div>
                    <p class="status-main">
                      {{ getInvoiceStatus(invoice.dueDate, invoice.status, invoice.date) }}
                    </p>
                    <div class="mobile-view">{{ intlFormat(+invoice.amount) }}</div>
                  </div>
                </div>
              </router-link>
            </template>
            <template #menu>
              <ul>
                <li
                  v-if="!invoice.invoiceNumber && invoice.status == 'Planned'"
                  @click="
                    $router.push({ name: 'EditInvoice', params: { invoiceId: invoice.id, projectId: projectId } })
                  "
                >
                  Edit
                </li>
                <li v-if="!invoice.invoiceNumber && invoice.status == 'Planned'" @click="deleteInvoice(invoice.id)">
                  Delete
                </li>
                <li
                  v-if="invoice.invoiceNumber && invoice.status == 'Planned'"
                  @click="updateInvoiceStatus(invoice.id, 'Sent')"
                >
                  Change to Sent
                </li>
                <li
                  v-if="invoice.invoiceNumber && invoice.status != 'Paid' && invoice.status !== 'Archived'"
                  @click="updateInvoiceStatus(invoice.id, 'Paid')"
                >
                  Change to Paid
                </li>
                <li v-if="invoice.invoiceNumber && invoice.fileName != null" @click="downloadInvoice(invoice.id)">
                  Download
                </li>
                <li
                  v-if="invoice.invoiceNumber && invoice.status != 'Paid' && invoice.status !== 'Archived'"
                  @click="archiveInvoice(invoice.id)"
                >
                  Archive
                </li>
              </ul>
            </template>
          </TableTile>
        </TableLayout>
      </template>
    </DashboardSection>
  </div>
  <div class="no-documents" v-else>
    <NoResult title="You haven't added any invoices yet">
      <CustomButton
        @click="$router.push({ name: 'EditProject', params: { id: projectId } })"
        label="Add a new invoice"
        color="blue"
      />
    </NoResult>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import DashboardSection from '@/components/atom/DashboardSection.vue'
import CustomButton from '@/components/atom/CustomButton.vue'
import TableLayout from '@/components/templates/TableLayout.vue'
import TableTile from '@/components/templates/TableTile.vue'
import NoResult from '@/components/molecules/NoResult.vue'
import authApi from '@/api/Authenticated'
import { useRoute } from 'vue-router'
import { projectTypes } from '@shared/*'
import useDateFormatter from '@/utils/dateFormatter'
import { intlFormat } from '@/utils/currencyFunctions'

export default defineComponent({
  components: {
    DashboardSection,
    CustomButton,
    TableLayout,
    TableTile,
    NoResult
  },
  setup() {
    const route = useRoute()
    const projectId = +route.params.id
    const { dateFormatter } = useDateFormatter()

    const projectDetails = ref<projectTypes.ProjectInvoiceResponse | { invoices: projectTypes.InvoicesArray }>({
      invoices: []
    })

    const currentSort = ref('')
    const currentSortDir = ref('desc')
    const calculateAmount = function () {
      projectDetails.value.invoices = projectDetails.value.invoices.map((e: any) => {
        const totalAmount = e.invoiceItems
          .map((item: any) => {
            return item.unitsCount * item.costPerUnit
          })
          .reduce((a: any, b: any) => a + b, 0)
        return {
          ...e,
          amount: totalAmount
        }
      })
    }
    const sortAndFetch = async (col: string) => {
      if (currentSort.value === col && col !== 'amount') {
        currentSortDir.value = currentSortDir.value === 'asc' ? 'desc' : 'asc'
      } else if (col === 'amount') {
        if (currentSortDir.value === 'desc') {
          projectDetails.value.invoices = projectDetails.value.invoices.sort((x: any, y: any) => {
            return y.amount - x.amount
          })
        } else {
          projectDetails.value.invoices = projectDetails.value.invoices.sort((x: any, y: any) => {
            return x.amount - y.amount
          })
        }
        currentSortDir.value = currentSortDir.value === 'asc' ? 'desc' : 'asc'
        currentSort.value = col

        return
      } else {
        currentSort.value = col
      }
      projectDetails.value = await authApi.getProjectInvoices(projectId, { [currentSort.value]: currentSortDir.value })
      calculateAmount()
    }

    sortAndFetch('date')

    const getInvoiceStatus = function (dueDate: Date, status: string, invoiceDate: Date) {
      const todayDate = new Date()
      invoiceDate = new Date(invoiceDate)
      dueDate = new Date(dueDate)
      const diffTime = Math.ceil((new Date(dueDate).getTime() - todayDate.getTime()) / (1000 * 60 * 60 * 24))
      if (status === 'Paid') {
        return 'Paid'
      } else if (status === 'Sent') {
        return 'Sent'
      } else if (status === 'Archived') {
        return 'Archived'
      } else if (invoiceDate > todayDate) {
        return 'upcoming'
      } else if (invoiceDate < todayDate && dueDate > todayDate && diffTime > 3) {
        return 'Due'
      } else if (dueDate > todayDate && diffTime <= 3) {
        return 'Payment Due'
      } else if (dueDate < todayDate) {
        return 'Over Due'
      } else {
        return ''
      }
    }

    const getInvoiceStatusClass = function (dueDate: Date, status: string, invoiceDate: Date) {
      const todayDate = new Date()
      invoiceDate = new Date(invoiceDate)
      dueDate = new Date(dueDate)
      const diffTime = Math.ceil((new Date(dueDate).getTime() - todayDate.getTime()) / (1000 * 60 * 60 * 24))
      if (status === 'Paid') {
        return 'status-green'
      } else if (status === 'Sent') {
        return 'status-green'
      } else if (status === 'Archived') {
        return 'status-grey'
      } else if (invoiceDate > todayDate) {
        return 'status-grey'
      } else if (invoiceDate < todayDate && dueDate > todayDate && diffTime > 3) {
        return 'status-yellow'
      } else if (dueDate > todayDate && diffTime <= 3) {
        return 'status-yellow'
      } else if (dueDate < todayDate) {
        return 'status-red'
      } else {
        return ''
      }
    }

    const deleteInvoice = async (invoiceId: number) => {
      const res = await authApi.deleteInvoice(+projectId, +invoiceId)
      if (res) {
        const index = projectDetails.value.invoices.findIndex((e) => e.id === invoiceId)
        if (index !== -1) {
          projectDetails.value.invoices.splice(index, 1)
        }
      }
    }
    const updateInvoiceStatus = async (invoiceId: number, status: any) => {
      const value = {
        status: status
      }

      const res = await authApi.updateInvoiceStatus(+projectId, +invoiceId, value)
      if (res) {
        await sortAndFetch('date')
      }
    }
    const downloadInvoice = async (invoiceId: number) => {
      const res = await authApi.downloadInvoice(+projectId, +invoiceId)
      if (res) {
        window.open(res.link, '_blank')
      }
    }

    const archiveInvoice = async (invoiceId: number) => {
      updateInvoiceStatus(invoiceId, 'Archived')
    }

    return {
      projectId,
      dateFormatter,
      projectDetails,
      getInvoiceStatus,
      sortAndFetch,
      getInvoiceStatusClass,
      deleteInvoice,
      updateInvoiceStatus,
      downloadInvoice,
      intlFormat,
      archiveInvoice
    }
  }
})
</script>

<style lang="sass" scoped>
$leftWidth: 10rem
$rightWidth: 5rem
.tile-tile
  font-weight: bold
  font-size: 16px
.tile-subtitle
  font-size: 12px
  color: map-get($greyShades, 'greyShade80')
.left-content, .right-content
  display: flex
  align-items: center
  @media( max-width: $md-breakpoint)
    min-width: 6rem
  & > *
    text-align: center
    color: $primary
    font-size: 14px
.left-content > *
  min-width: $leftWidth
  @media( max-width: $md-breakpoint)
    min-width: 6.5rem
  & > *
    padding-left: 2rem
.right-content > *
  min-width: $rightWidth
.right-heading-item
  min-width: $rightWidth
.left-heading-item
  min-width: $leftWidth
  cursor: pointer
  display: flex
  align-items: center
  justify-content: center
  @media( max-width: $md-breakpoint)
    min-width: 7rem
.left-text
  text-align: left
  color: $primary
  margin-left: 0.5rem
.mobile-hide
  @media( max-width: $md-breakpoint)
    display: none
.invoice-main
  border-left: 4px solid
  border-radius: 4px
.status-red
  border-color: $red
  .status-main
    background-color: $red
.status-yellow
  border-color: $mustard
  .status-main
    background-color: $mustard
.status-grey
  border-color: map-get($greyShades, 'greyShade30')
  .status-main
    background-color: map-get($greyShades, 'greyShade30')
.status-green
  border-color: $green
  .status-main
    background-color: $green
.status-main
  display: flex
  padding: 0.5rem
  border-radius: 2px
  color: $white
.highlight
  font-weight: bold
  font-size: 16px
.mobile-view
  display: none
  @media( max-width: $md-breakpoint)
    display: block
</style>
