<template>
  <div class="no-result">
    <h2>{{ title }}</h2>
    <p>{{ subTitle }}</p>
    <slot />
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'NoResult',
  props: {
    title: String,
    subTitle: String
  }
})
</script>

<style scoped lang="sass">
.no-result
  max-width: 360px
  margin: 3rem auto
  p
    margin-bottom: 2rem
</style>
